<template>
    <div>
        <NuxtLoadingIndicator :color="config.loading?.color ?? '#923C3B'" />
        <GoogleTagmanager />

        <ClientOnly>
            <CookieConsentTheCookiebar />
        </ClientOnly>

        <slot />

        <TheFooter />
    </div>
</template>

<script setup>
const config = useAppConfig();

useHead({
    htmlAttrs: {
        lang: 'en'
    },
});

onMounted(() => {
    navigator.serviceWorker.getRegistrations().then(async(registrations) => { // fixme: temp fix unregister invalid service workers
        if (registrations.length) {
            for (const registration of registrations) {
                await registration.unregister();
            }
            window.location.reload();
        }
    });
});
</script>

<script>
export default {
    computed: {
        theRoutePath() {
            return this.$route.path;
        }
    },
    watch: {
        theRoutePath() {
            document.querySelector('#main').focus();
        }
    }
};
</script>
